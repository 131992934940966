import React from "react";

import { BsLightningCharge } from "react-icons/bs";
import { Bounce } from "react-reveal";

function Electrical() {
  const services = [
    {
      servicename:
        "Electrical audit (Inspection and Testing), Installation, repair and maintenance",
    },
    {
      servicename:
        "Refrigeration and Air Conditioning Cold rooms, Chillers, Refrigeration and Air",
    },
    {
      servicename: "Conditioning Installations, repairs and maintenance",
    },
    {
      servicename:
        "Electrical installation, maintenance and repairs, Lighting and Electrical safety",
    },
    {
      servicename:
        "Low voltage circuitry calibration (instrumentation and controls adjustment)",
    },
    {
      servicename: "Power factor correction plants and stabilizers",
    },
    {
      servicename:
        "Installation of high voltage power control panels, switch gears, distribution boards, auto change over systems from mains power to standby generators",
    },
    {
      servicename:
        "Electronics, solar power accessories installation and maintenance",
    },
  ];
  return (
    <div>
      <div className="my-3  bg-white">
        <div className="h-[6rem] flex items-center justify-center bg-black/10 px-[5rem]">
          <Bounce top cascade>
            <p className="text-green-500 text-[2rem] animate__animated animate__fadeInRight md:text-[3rem] font-bold">
              Electrical
            </p>
          </Bounce>
        </div>
      </div>

      <div className="grid px-4 md:px-10 md:grid-cols-2 gap-6">
        <div>
          <img
            src={require("../assets/images/electrical.png")}
            alt=""
            className="rounded shadow-lg shadow-green-300/40"
          />
        </div>
        <div className="rounded shadow shadow-green-300/50 p-6">
          <p className="text-[23px] py-1 px-6 my-6 border-r border-b w-fit border-green-600 rounded text-green-600">
            Electrical Services
          </p>
          <ul className="text-green-700">
            {services.map((service, index) => (
              <li key={index} className="p-1">
                <div className="flex space-x-4 justify-start items-center">
                  <p className="w-5 h-5">
                    <BsLightningCharge className="text-green-600 text-[17px]" />
                  </p>

                  <p>{service.servicename}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Electrical;
