import React from "react";
import Marquee from "react-fast-marquee";
import agol from "../assets/images/partners/AGOL.png";
import addax from "../assets/images/partners/addax.jpg";
import kisumu from "../assets/images/partners/Gvt-Kisumu-300x212.png";
import hashi from "../assets/images/partners/Hashi.jpeg";
import hass from "../assets/images/partners/Hass_Logo-300x300.png";
import kpa from "../assets/images/partners/KPA.png";
import kpc from "../assets/images/partners/KPC.jpeg";
import kpr from "../assets/images/partners/KPR.png";
import lerexcom from "../assets/images/partners/Lerexcom.png";
import sp from "../assets/images/partners/SP.png";
import tullow from "../assets/images/partners/Tullow_Oil-Logo.wine_-300x200.png";
import bakri from "../assets/images/partners/bakri.png";
import oilibya from "../assets/images/partners/oilibya-logo.png";
import oilcom from "../assets/images/partners/oilcom-logo.png";
import gulf from "../assets/images/partners/gulf-logo.png";

function Partners() {
  const partners = [
    { imageUrl: agol },
    { imageUrl: addax },
    { imageUrl: kisumu },
    { imageUrl: hashi },
    { imageUrl: hass },
    { imageUrl: kpa },
    { imageUrl: kpc },
    { imageUrl: kpr },
    { imageUrl: lerexcom },
    { imageUrl: sp },
    { imageUrl: tullow },
    { imageUrl: bakri },
    { imageUrl: oilibya },
    { imageUrl: oilcom },
    { imageUrl: gulf },
  ];
  return (
    <div className="">
      <p className="text-center font-bold border-b border-green-500 text-green-600 text-[1.5rem] md:text-[2.5rem] my-5">
        Our Clients
      </p>
      <Marquee
        delay={5}
        pauseOnHover={true}
        gradient={true}
        gradientWidth={50}
        gradientColor={[163, 239, 190]}
        className="py-5 flex items-center"
      >
        {partners.map((partner, index) => (
          <div key={index} className=" mx-2">
            <img
              src={partner.imageUrl}
              alt=""
              className="h-[3rem] md:h-[5rem] rounded-lg shadow-lg"
            />
          </div>
        ))}
      </Marquee>
    </div>
  );
}

export default Partners;
