import React from "react";
import { Zoom } from "react-reveal";

function Environment() {
  return (
    <div>
      <Zoom top cascade>
        <p className="text-green-900 w-fit text-[1rem] md:text-[2rem] font-bold px-10 text-center border-b border-green-400 mx-auto mt-5">
          Environment, Health and Safety Policy
        </p>
      </Zoom>
      <div className="md:px-[5%] px-4 text-center">
        <p className="mt-5 text-green-700 md:text-xl">
          <span className="text-green-500 font-bold">Comacon Limited,</span> all
          divisions is committed to managing environmental, health and safety
          (HSE) matters as an integral part of our business. We will provide our
          clients with responsive, safe and reliable services while adhering to
          the ideals of sustainable environmental management requirements. We
          believe that sound environmental, health and safety policy contributes
          to our competitive strength and benefits our clients, shareholders,
          and employees by contributing to the overall well being and economic
          health of the communities we serve
        </p>
        <p className="py-6 text-green-800 mx-10">
          We will do so by adhering to the following principles:
        </p>
      </div>
      {/* compliance */}
      <div className="md:px-[5%] px-8">
        <p className="text-[1.3rem] w-fit px-6 border-b mx-auto border-green-400 font-[500] text-green-700">
          COMPLIANCE
        </p>
        <p className="mt-5 text-center text-green-800 md:text-lg">
          We will comply with all applicable laws and regulations and will
          implement programs and procedures to assure compliance. Strict
          compliance with HSE standards will be key ingredient in the training,
          performance reviews and incentives of all employees.
        </p>
        <p className="mt-5 text-center text-green-800 md:text-lg">
          Where existing laws and regulations are not adequate to assure
          protection of human health, safety and environment, we will establish
          and meet our own HSE quality standards.
        </p>
      </div>
      {/* prevention */}
      <div className="md:px-[5%] mt-6 px-8">
        <p className="text-[1.3rem] w-fit px-6 border-b mx-auto border-green-400 font-[500] text-green-700">
          PREVENTION
        </p>
        <p className="mt-5 text-center text-green-800 md:text-lg">
          We will employ management systems and procedures specifically designed
          to prevent activities and/or conditions that pose a threat to human
          health, safety or the environment. We will minimize risk and protect
          our employees and the communities in which we operate by employing
          safe technologies and operating procedures, as well as being prepared
          for emergencies.
        </p>
        <p className="mt-5 text-center text-green-800 md:text-lg">
          We will strive to prevent releases to the atmosphere, land or water.
          We will minimize the amount and toxicity of waste generated and will
          ensure the safe treatment and disposal of waste.
        </p>
      </div>
      {/* Communication */}
      <div className="md:px-[5%] mt-6 px-8">
        <p className="text-[1.3rem] w-fit px-6 border-b mx-auto border-green-400 font-[500] text-green-700">
          COMMUNICATION
        </p>
        <p className="mt-5 text-center text-green-800 md:text-lg">
          We will communicate our commitment to HSE quality to our employees,
          suppliers and Clients. We will solicit their input in meeting our HSE
          goals and in turn will offer assistance to meet their goals. for
          emergencies.
        </p>
      </div>
      {/* Continuous improvement */}
      <div className="md:px-[5%] mt-6 mb-20 px-8">
        <p className="text-[1rem] md:text-[1.3rem] w-fit px-6 border-b mx-auto border-green-400 font-[500] text-green-700">
          CONTINUOUS IMPROVEMENT
        </p>
        <p className="mt-5 text-center text-green-800 md:text-lg">
          We will continuously seek to adhere to these principles, and will
          periodically report to our stakeholders
        </p>
      </div>
    </div>
  );
}

export default Environment;
