import { BrowserRouter, Route, Routes } from "react-router-dom";
import Appbar from "./components/Appbar";
import Home from "./pages/Home";
import About from "./pages/About";
import Projects from "./pages/Projects";
import Contact from "./pages/contact";
import Civil from "./pages/civil";
import Electrical from "./pages/electrical";
import Mechanical from "./pages/mechanical";
import Supplies from "./pages/supplies";
import ErrorPage from "./pages/Errorpage";
import SmoothScroll from "./components/smoothScroll";
import Services from "./pages/Services";
import Environment from "./pages/Environment";
import Lifting from "./pages/Lifting";
import Oilgas from "./pages/Oilgas";

function App() {
  return (
    <BrowserRouter>
      <SmoothScroll>
        <Routes>
          <Route element={<Appbar />}>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/contact-us" element={<Contact />} />
            <Route path="/civil" element={<Civil />} />
            <Route path="/electrical" element={<Electrical />} />
            <Route path="/mechanical" element={<Mechanical />} />
            <Route path="/supplies" element={<Supplies />} />
            <Route path="/services" element={<Services />} />
            <Route path="/environ-safety" element={<Environment />} />
            <Route path="/heavy-lifting" element={<Lifting />} />
            <Route path="/Oil&gas" element={<Oilgas />} />
            <Route path="*" element={<ErrorPage />} />
          </Route>
        </Routes>
      </SmoothScroll>
    </BrowserRouter>
  );
}

export default App;
