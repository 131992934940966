import React from "react";
import { Carousel } from "antd";
import { AiFillLinkedin } from "react-icons/ai";
import { BiLogoFacebook } from "react-icons/bi";
import { BsTwitter, BsInstagram } from "react-icons/bs";
import {
  FaHandPointRight,
  FaStar,
  FaBinoculars,
  FaHeart,
} from "react-icons/fa";
import Reviews from "../components/Reviews";
import { Link } from "react-router-dom";
import Partners from "../components/partners";
import { MdMiscellaneousServices } from "react-icons/md";
import { FaArrowsDownToPeople } from "react-icons/fa6";
import { GiSpectacles } from "react-icons/gi";
import { MdOutlineReduceCapacity } from "react-icons/md";
import Welcome from "../components/welcome";
import { Bounce, Slide } from "react-reveal";

function Home() {
  return (
    <div className="bg-white">
      {/* <div className="min-h-[70vh] bg-cover bg-center overflow-hidden bg-video">
        <div className="bg-white/50 w-full h-[70vh]">
          <div className="p-2 flex flex-col md:pt-10 pt-10 h-full w-full md:px-[10%]  bg-black/70 md:bg-black/70 text-center md:text-left">
            <p className="mt-[40%] md:mt-0 font-[500] md:font-[300] animate__animated animate__fadeInRight text-[2.5rem] md:text-[6rem] text-[#54B151]">
              <Bounce left cascade>
                Quality
              </Bounce>
            </p>
            <p className="md:ml-16 font-[500] animate__animated animate__fadeInRight text-[2.5rem] md:text-[6rem] text-[#54B151]">
              <Bounce right cascade>
                Assurance
              </Bounce>
            </p>

            <Link
              to="/about"
              className="w-fit px-12 animate__animated font-[500] text-[19px] animate__fadeInUp py-3 cursor-pointer self-center border border-green-200 rounded-full hover:bg-green-600/80 shadow-lg md:mt-10 my-4 hover:text-gray-100 text-gray-200"
            >
              Read More
            </Link>
          </div>
        </div>
      </div> */}

      <Carousel
        dotPosition="bottom"
        autoplay
        lazyLoad="progressive"
        pauseOnHover={false}
        pauseOnFocus={true}
        autoplaySpeed={4000}
        speed={1000}
        effect="fade"
        easing="linear"
        next
        className="h-[70vh] md:h-[90vh] bg-black/90"
      >
        {/* first caroussel */}
        <div className=" overflow-hidden h-[70vh] md:h-[90vh] w-full bg-[url('https://unsplash.com/photos/sgYamIzhAhg/download?ixid=M3wxMjA3fDB8MXxzZWFyY2h8MTV8fGJ1aWxkaW5nJTIwY29uc3RydWN0aW9ufGVufDB8fHx8MTY5NTQ2MDU3MXww&force=true&w=1920')]">
          <div className="p-2 flex flex-col md:pt-10 pt-8 h-full w-full md:px-[10%]  bg-gradient-to-r from-green-600  via-black/20 backdrop-blur-[1px] text-center md:text-left">
            {/* <div className="flex justify-center items-center">
              <img
                src={require("../assets/images/logocomacon.png")}
                alt=""
                className="h-10 w-auto rounded-full"
              />
            </div> */}
            <p className="mt-[40%] md:mt-0 font-[500] md:font-[300] animate__animated animate__fadeInRight text-[2.5rem] md:text-[6rem] text-white">
              Operational
            </p>
            <p className="md:ml-16 font-[500] animate__animated animate__fadeInRight text-[2.5rem] md:text-[6rem] text-white">
              Divisions
            </p>

            <Link
              to="/about"
              className="w-fit px-12 animate__animated font-[500] text-[19px] animate__fadeInUp py-3 cursor-pointer self-center border border-green-200 rounded-full hover:bg-green-600/80 shadow-lg md:mt-10 my-4 hover:text-gray-100 text-gray-200"
            >
              Read More
            </Link>
          </div>
        </div>
        {/* second carousel */}
        <div className=" overflow-hidden h-[70vh] md:h-[90vh] w-full bg-center md:bg-top bg-[url('https://unsplash.com/photos/FlPc9_VocJ4/download?ixid=M3wxMjA3fDB8MXxzZWFyY2h8Mnx8cXVhbGl0eSUyMGFzc3VyYW5jZXxlbnwwfHx8fDE2OTU0NjA3NzR8MA&force=true&w=1920')]">
          <div className="p-2 flex flex-col md:pt-10 pt-10 h-full w-full md:px-[10%]  bg-gradient-to-r from-green-600  via-black/20 text-center md:text-left">
            <p className="mt-[40%] md:mt-0 font-[500] md:font-[300] animate__animated animate__fadeInRight text-[2.5rem] md:text-[6rem] text-white">
              Quality
            </p>
            <p className="md:ml-16 font-[500] animate__animated animate__fadeInRight text-[2.5rem] md:text-[6rem] text-white">
              Assurance
            </p>

            <Link
              to="/about"
              className="w-fit px-12 animate__animated font-[500] text-[19px] animate__fadeInUp py-3 cursor-pointer self-center border border-green-200 rounded-full hover:bg-green-600/80 shadow-lg md:mt-10 my-4 hover:text-gray-100 text-gray-200"
            >
              Read More
            </Link>
          </div>
        </div>
        {/* Third carousel */}
        <div className=" overflow-hidden h-[70vh] md:h-[90vh] w-full bg-center bg-[url('https://unsplash.com/photos/bGMyTnSlYvE/download?ixid=M3wxMjA3fDB8MXxhbGx8fHx8fHx8fHwxNjk1NDYzMzk4fA&force=true&w=1920')]">
          <div className="p-2 flex flex-col md:pt-10 pt-10 h-full w-full md:px-[10%] bg-gradient-to-r from-green-600  via-black/20 text-center md:text-left">
            <p className="mt-[40%] md:mt-0 font-[500] md:font-[300] animate__animated animate__fadeInRight text-[2.5rem] md:text-[6rem] text-white">
              Comacon
            </p>
            <p className="md:ml-16 font-[500] animate__animated animate__fadeInRight text-[2.5rem] md:text-[6rem] text-white">
              Limited
            </p>

            <Link
              to="/about"
              className="w-fit px-12 animate__animated font-[500] text-[19px] animate__fadeInUp py-3 cursor-pointer self-center border border-green-200 rounded-full hover:bg-green-600/80 shadow-lg md:mt-10 my-4 hover:text-gray-100 text-gray-200"
            >
              Read More
            </Link>
          </div>
        </div>
      </Carousel>
      {/* <div className="grid lg:grid-cols-2 gap-4 my-8 p-2 md:p-5 animate__animated animate__fadeIn">
        <div className="rounded shadow-lg p-2 bg-green-400/30 md:p-10">
          <p className="mt-1 font-bold text-lg text-gray-700">
            Why Comacon & Associates?
          </p>
          <p className="mt-4 text-gray-700">
            Comacon differentiates itself from traditional engineering firms by
            providing complete solutions for project implementation by
            emphasizing a modular engineering strategy early in the
            project-planning process.
          </p>
          <p className="mt-4 text-gray-700">
            With more than 1 million sq. ft. of manufacturing space worldwide,
            modules are precisely engineered for ease in transport via truck,
            rail, or sea. Comacon's modular approach can successfully be applied
            to a variety of industries.
          </p>
          <p className="mt-4 text-gray-700">
            With office locations worldwide and projects in more than 60+
            countries across 6 continents, Comacon is ideally suited to provide
            modular solutions to a global client base, while providing
            exceptional value in controlled project risk, cost, schedule, and
            increased overall safety and quality.
          </p>
        </div>
        <div className="w-full p-5">
          <img
            src={require("../assets/images/worldmap.jpg")}
            className="h-full w-auto rounded shadow"
            alt=""
          />
        </div>
      </div> */}
      <Welcome />
      {/* Why choose us */}
      <div className="my-4 ">
        <div className="w-full py-10 text-green-700 h-full">
          <div className="border-green-600 bg-fixed bg-center text-center flex justify-center bg-white')]">
            <div className="py-4">
              <Bounce top cascade>
                <p className="text-[1.5rem] border-b border-t border-gray-400 w-fit px-5 md:text-[2.5rem] uppercase font-bold">
                  Why Choose Comacon
                </p>
              </Bounce>
            </div>
          </div>
          <div className="grid lg:grid-cols-4 mt-5 gap-4 border-b px-8 md:px-20 border-green-600 pb-8">
            <div>
              <img
                src={require("../assets/images/Services.png")}
                alt=""
                className="h-[10rem] w-auto rounded-md shadow-lg shadow-green-500/50"
              />
              <p className="text-[1.8rem] font-[400] mt-8">
                Creditable Integrity
              </p>
              <p>
                Over the years, we have gained a reputation for integrity and
                trust from our customers.
              </p>
            </div>
            <div>
              <img
                src={require("../assets/images/Parts.png")}
                alt=""
                className="h-[10rem] w-auto rounded-md shadow-lg shadow-green-500/50"
              />
              <p className="text-[1.8rem] font-[400] mt-8">
                Adaptability & Flexibility
              </p>
              <p>
                Understanding our clients has given us the opportunity to adopt
                various engineering requirements to meet project demand.
              </p>
            </div>
            <div>
              <img
                src={require("../assets/images/Tech-Support.png")}
                alt=""
                className="h-[10rem] w-auto rounded-md shadow-lg shadow-green-500/50"
              />
              <p className="text-[1.8rem] font-[400] mt-8">
                Customer Satisfaction
              </p>
              <p>
                we believe that the “CUSTOMER is the KING” and our work is never
                complete without the final nod of approval from our clients.
              </p>
            </div>
            <div>
              <img
                src={require("../assets/images/learning.jpg")}
                alt=""
                className="h-[10rem] w-auto rounded-md shadow-lg shadow-green-500/50"
              />
              <p className="text-[1.8rem] font-[400] mt-8 ">
                Continuous Learning
              </p>
              <p>
                We believe in integrating the latest in construction and
                engineering technology to ensure that our projects are not
                compromised by disruptions or obsolescence.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* End why choose us */}
      <div className="px-4 md:px-28 text-center">
        <h1 className="font-[400] text-2xl text-gray-700">
          With over 30 years of turnkey project execution, Comacon is an
          industry leader in engineering, manufacturing, and installation of
          modular process systems.
        </h1>
      </div>
      <div className="my-4 bg-fixed bg-[url('https://unsplash.com/photos/HpPmiduLDC0/download?ixid=M3wxMjA3fDB8MXxzZWFyY2h8Nnx8ZmFjdG9yeXxlbnwwfHx8fDE2OTIwNDYyMzZ8MA&force=true&w=1920')]">
        <div className="w-full px-6 lg:px-28 py-10 text-gray-200 h-full bg-green-950/80">
          <div className="border-b border-gray-200 text-center">
            <p className="text-[2.5rem] font-bold mb-4">About Us</p>
          </div>
          <div className="grid lg:grid-cols-4 mt-5 gap-4 border-b border-gray-200 pb-8">
            <div className="flex flex-col justify-center items-center">
              {/* <img
                src={require("../assets/images/Services.png")}
                alt=""
                className="h-[10rem] w-auto"
              /> */}
              <GiSpectacles className="text-[5rem] text-center" />
              <p className="text-[1.8rem] font-[400] mt-8">Specialty</p>
              <p>
                Comacon Limited is a leading specialist Civil, Mechanical &
                Electrical contractor within East Africa with a history dating
                back to January 2007.
              </p>
            </div>
            <div className="flex flex-col justify-center items-center">
              {/* <img
                src={require("../assets/images/Parts.png")}
                alt=""
                className="h-[10rem] w-auto"
              /> */}
              <MdMiscellaneousServices className="text-[5rem] text-center" />
              <p className="text-[1.8rem] font-[400] mt-8">Other Services</p>
              <p>
                Today, Comacon Limited forms part of Building, Structural & Mass
                Concrete casting which is ultimately part of the world’s leading
                building technology and associated services
              </p>
            </div>
            <div className="flex flex-col justify-center items-center">
              {/* <img
                src={require("../assets/images/Tech-Support.png")}
                alt=""
                className="h-[10rem] w-auto"
              /> */}
              <MdOutlineReduceCapacity className="text-[5rem] text-center" />
              <p className="text-[1.8rem] font-[400] mt-8">Our Capabilities</p>
              <p>
                Comacon Limited has the capability and resources to undertake
                projects of all sizes and complexity throughout East Africa.
              </p>
            </div>
            <div className="flex flex-col justify-center items-center">
              {/* <img
                src={require("../assets/images/Products.jpg")}
                alt=""
                className="h-[10rem] w-auto"
              /> */}
              <FaArrowsDownToPeople className="text-[5rem] text-center" />
              <p className="text-[1.8rem] font-[400] mt-8">Our Team</p>
              <p>
                A well qualified and experienced team of Civil, Mechanical and
                Electrical engineers, coupled with an equally experienced and
                energetic operations team, guarantees value for money solutions
                of the highest standard
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* Start mission & vision */}
      <Slide bottom>
        <div>
          <div className="grid md:grid-cols-3 gap-4 text-green-800 p-4 md:p-8">
            <div className="p-5 md:p-10 rounded border text-gray-700 bg-white shadow-lg shadow-green-500/50 border-green-500">
              <div className="flex flex-col space-y-2 justify-center items-center">
                <FaBinoculars className="text-[25px] text-green-500" />
                <p className="text-2xl text-green-600 mb-4 text-center font-bold px-4 border-b border-gray-200">
                  Our Vision
                </p>
              </div>

              <p className="text-[18px] mt-5">
                To undertake the engineering and construction business with a
                focus on becoming the leader in product costing while building
                excellence in every aspect to meet customers stringent
                requirements regarding quality, on-time delivery, safety and
                environmental concerns
              </p>
            </div>
            <div className="p-5 md:px-10 py-5 text-gray-700 bg-white shadow-green-500/50 shadow-lg rounded border border-green-500">
              <div className="flex flex-col space-y-2 justify-center items-center">
                <FaStar className="h-6 text-[25px] text-green-500" />
                <p className="text-2xl text-green-600 mb-4 text-center font-bold border-b border-gray-200">
                  Our Mission
                </p>
              </div>

              <p className="text-[18px] mt-5">
                To further develop our corporate strengths, we have established
                a corporate mandate to maintain strong core values that truly
                reflect the company's philosophy. Having stood the test of time,
                transparency and good corporate governance remain our top
                agenda. At Comacon the principle supporting this philosophy are
                permanently ingrained in our corporate values
              </p>
            </div>
            <div className="p-5 md:p-10 text-gray-700 bg-white shadow-lg shadow-green-500/50 rounded border border-green-600">
              <div className="flex flex-col space-y-2 justify-center items-center">
                <FaHeart className="text-[25px] text-green-500" />
                <p className="text-2xl mb-4 text-green-600 text-center font-bold border-b border-gray-200">
                  Our Core Values
                </p>
              </div>

              <div className="py-5 flex  space-x-2 text-[18px]">
                <p className="h-5 w-5 pt-1">
                  <FaHandPointRight />
                </p>
                <p>Sustained efforts to enhance customer value and quality</p>
              </div>
              <div className="py-5 flex space-x-2 text-[18px]">
                <p className="h-5 w-5 pt-1">
                  <FaHandPointRight />
                </p>
                <p>Ethical and professional service</p>
              </div>
              <div className="py-5 flex  space-x-2 text-[18px]">
                <p className="h-5 w-5 pt-1">
                  <FaHandPointRight />
                </p>
                <p>
                  Compliance and respect for all community, environmental and
                  legal requirements
                </p>
              </div>
            </div>
          </div>
        </div>
      </Slide>
      {/* End mission */}
      {/* Featured */}
      {/* <div className="my-4 text-center">
        <p className="font-[500] text-[24px] text-gray-600">Featured News</p>
      </div> */}
      {/* Featured body */}
      {/* <div className="px-4 w-full md:px-32 my-4">
        <div className="grid md:grid-cols-4 gap-4">
          <div>
            <img
              src={require("../assets/images/Tech-Support.png")}
              alt=""
              className="h-[13rem] w-auto"
            />
            <p className="text-[1.5rem] text-gray-600 font-[300] mt-8">
              Expanding Capabilities and Square Footage to Support the Oil and
              Gas Industry
            </p>
            <p className="text-gray-600">
              Comacon's Denver operations, located in Northglenn, Colorado, have
              officially relocated to Louisville, Colorado, to be housed under
              roof with Servtech, Inc., a Comacon company.
            </p>
          </div>

          <div>
            <img
              src={require("../assets/images/Tech-Support.png")}
              alt=""
              className="h-[13rem] w-auto"
            />
            <p className="text-[1.5rem] text-gray-600 font-[300] mt-8">
              Expanding Capabilities and Square Footage to Support the Oil and
              Gas Industry
            </p>
            <p className="text-gray-600">
              Comacon's Denver operations, located in Northglenn, Colorado, have
              officially relocated to Louisville, Colorado, to be housed under
              roof with Servtech, Inc., a Comacon company.
            </p>
          </div>

          <div>
            <img
              src={require("../assets/images/Tech-Support.png")}
              alt=""
              className="h-[13rem] w-auto"
            />
            <p className="text-[1.5rem] text-gray-600 font-[300] mt-8">
              Expanding Capabilities and Square Footage to Support the Oil and
              Gas Industry
            </p>
            <p className="text-gray-600">
              Comacon's Denver operations, located in Northglenn, Colorado, have
              officially relocated to Louisville, Colorado, to be housed under
              roof with Servtech, Inc., a Comacon company.
            </p>
          </div>

          <div>
            <img
              src={require("../assets/images/Tech-Support.png")}
              alt=""
              className="h-[13rem] w-auto"
            />
            <p className="text-[1.5rem] text-gray-600 font-[300] mt-8">
              Expanding Capabilities and Square Footage to Support the Oil and
              Gas Industry
            </p>
            <p className="text-gray-600">
              Comacon's Denver operations, located in Northglenn, Colorado, have
              officially relocated to Louisville, Colorado, to be housed under
              roof with Servtech, Inc., a Comacon company.
            </p>
          </div>
        </div>
      </div> */}
      {/* Why section */}
      <div
        id="prefabrication"
        className="my-4 flex justify-center items-center bg-[url('https://unsplash.com/photos/hkJNx0EDbjE/download?ixid=M3wxMjA3fDB8MXxhbGx8fHx8fHx8fHwxNjkyMDgwOTQ3fA&force=true&w=1920')]"
      >
        <div className="w-full px-8 md:px-28 py-20 text-gray-200 h-full bg-green-950/80">
          <div className=" border-gray-200 text-center">
            <p className="text-2xl md:text-3xl mb-4">
              Why Prefabrication and Preassembly?
            </p>
          </div>
          <div className="grid mx-auto md:grid-cols-3 w-full mt-5 gap-4 border-b border-t border-gray-200 py-8">
            <div>
              {/* <img
                src={require("../assets/images/Services.png")}
                alt=""
                className="h-[10rem] w-auto"
              /> */}

              <p className="mt-8 text-gray-200 text-center md:text-left font-[300] md:mr-10">
                Preliminary planning is essential for the successful execution
                of prefabricated, preassembled, and modularized projects.
                Comacon assists clients in the early phases of a project by
                carrying out capital budgeting, feasibility studies, marketing
                planning, and process flow diagrams to guarantee a successful
                project implementation.
              </p>
            </div>
            <div>
              {/* <img
                src={require("../assets/images/Parts.png")}
                alt=""
                className="h-[10rem] w-auto"
              /> */}
              <p className="mt-8 text-gray-200 text-center md:text-left font-[300] md:mr-10">
                Comacon's distinctive approach to the modular design-build
                construction process aims to offer clients a high-performance,
                integrated solution that increases return on invested capital by
                enhancing project quality, increasing productivity during the
                design and construction phases, and hastening the deployment of
                systems and plant operations.
              </p>
            </div>
            <div>
              {/* <img
                src={require("../assets/images/Tech-Support.png")}
                alt=""
                className="h-[10rem] w-auto"
              /> */}

              <p className="mt-8 text-gray-200 text-center md:text-left font-[300] md:mr-10">
                From conceptual design all the way through project start-up and
                commissioning, Comacon's project management teams oversee
                projects. The project management team at Comacon can effectively
                manage the customer-driven KPIs of cost, schedule, and overall
                project quality since they are deeply involved in every stage of
                project execution.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* CEO Quote */}
      <p className="text-center text-green-700 text-[1.5rem] md:text-[2.5rem] border-b border-green-500 font-medium mt-10">
        What Drives Us
      </p>
      <div className="w-full md:w-10/12 mx-auto grid md:grid-cols-2 gap-4 mt-6 text-center mb-8">
        <div>
          <p className="w-full px-8 py-4 text-green-700 md:p-0 mx-auto text-[20px] font-[400] gap-4">
            Comacon separates process implementation from facility building,
            minimizing the impact of delays and unanticipated events on
            installation schedules. The modularized, prefabricated, and
            preassembled approach used by Comacon ensures consistency in the
            quality of the design, fabrication, installation, and, most
            crucially, the outcomes regardless of the project's size or
            location.
          </p>
        </div>
        <div className="border-t border-gray-600 px-4 pt-8">
          <p className="text-gray-600 text-[20px] font-[300] border-b border-gray-800 pb-10 mb-8">
            “When you practice something over and over again until it's perfect,
            you end up with a team that's capable of so much more than just the
            sum of its parts…”
          </p>
          <p className="text-gray-400 text-[13px] font-[500]">
            <span className="italic mt-4 text-gray-500 font-[500] text-[18px] px-2 mr-1 md:mr-4 border-r border-gray-300">
              Cosmas
            </span>
            Chairman & Director of Comacon Group
          </p>
        </div>
      </div>
      {/* Keep upto date */}
      <div className="text-center text-gray-700 my-8 flex justify-center items-center">
        <div className="flex flex-col justify-center items-center border border-gray-300 rounded shadow-lg w-[80%] md:w-2/3 p-10 md:p-10">
          <p className="text-gray-500 mb-10">
            Keep up to date with where we are and what we're up to - Follow us
            on social media
          </p>
          <div className="flex space-x-4">
            <a href="/">
              <AiFillLinkedin className="text-blue-600 text-[20px] " />
            </a>
            <a href="/">
              <BiLogoFacebook className="text-blue-600 text-[20px] " />
            </a>
            <a href="/">
              <BsTwitter className="text-blue-500 text-[20px] " />
            </a>
            <a href="/">
              <BsInstagram className="text-pink-500 text-[20px] " />
            </a>
          </div>
          {/* <div className="h-20"></div> */}
        </div>
      </div>
      {/* Partners */}
      <Partners />
      {/* Testimonies */}
      <Reviews />
    </div>
  );
}

export default Home;
