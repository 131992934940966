import React from "react";
import { Bounce } from "react-reveal";

function Oilgas() {
  return (
    <div>
      <div className="border-green-600 bg-fixed mt-10 bg-center text-center bg-white">
        <div className="py-4 bg-black/0">
          <Bounce top cascade>
            <p className="text-green-500 text-[2rem] animate__animated animate__fadeInRight md:text-[3rem] font-bold">
              Oil & Gas
            </p>
          </Bounce>
        </div>
      </div>

      <div className="grid md:grid-cols-2 px-2 md:px-10 gap-2 md:gap-8">
        <div className="min-h-[35rem] my-10 rounded-md shadow-xl overflow-hidden shadow-green-200  bg-[url('https://unsplash.com/photos/UoIiVYka3VY/download?ixid=M3wxMjA3fDB8MXxzZWFyY2h8OHx8b2lsJTIwYW5kJTIwZ2FzfGVufDB8fHx8MTY5NjIzMjAxMHww&force=true&w=1920')] bg-center bg-cover">
          <div className="bg-black/30 h-full w-full"></div>
        </div>
        <div className="p-2 md:p-10 border rounded my-3 md:my-10">
          <p className="text-[25px] py-1 px-6 my-6 border-r border-b w-fit border-green-600 rounded text-green-600">
            Oil and Gas
          </p>
          <p className="text-green-700 py-2 text-lg">
            With the upstream Oil & Gas firms operating in this region, Comacon
            is a reputable and well-known organization. With their professional
            endorsement, we have been able to maintain our position as the
            industry leader.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Oilgas;
