import React, { useState } from "react";
import { Button, Checkbox, Form, Input, Modal, Spin, message } from "antd";
import {
  UserOutlined,
  MailOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import { FloatButton } from "antd";
import { AiFillLinkedin, AiOutlinePhone, AiOutlineMail } from "react-icons/ai";
import { BiLogoFacebook } from "react-icons/bi";
import { BsTwitter, BsInstagram } from "react-icons/bs";
import { CiLocationOn } from "react-icons/ci";
import { FaWhatsapp } from "react-icons/fa";
import axios from "axios";
import { sendMails } from "../utilities/Apis";
import { Link } from "react-router-dom";

function Footer() {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [quote, setQuote] = useState(false);
  const showModal = () => {
    setOpen(true);
  };
  const showModal2 = () => {
    setQuote(true);
  };
  const handleOk = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setOpen(false);
    }, 3000);
  };
  const handleCancel = () => {
    setOpen(false);
    setQuote(false);
  };
  const subscribe = async (values) => {
    console.log(values);
    try {
    } catch (error) {}
  };

  const reqQuote = async (values) => {
    try {
      setLoading(true);
      const res = await axios.post(sendMails, {
        title: "Request for a quote",
        name: `${values.firstname} ${values.lastname}`,
        email: values.email,
        body: values.message,
      });
      if (res.data.success) {
        setQuote(false);
        message.info(
          "Your service quote request has been received we will reach back soon!"
        );
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  return (
    <div className="">
      <div className="bg-[url('https://unsplash.com/photos/aCniNTiIFd8/download?ixid=M3wxMjA3fDB8MXxzZWFyY2h8MTR8fG1hbnVmYWN0dXJpbmd8ZW58MHx8fHwxNjk1OTY2MzQxfDA&force=true&w=1920')] bg-center bg-cover bg-fixed ">
        <div className="flex flex-col md:flex-row justify-between p-10 py-16 bg-black/80">
          <p className="text-gray-200 font-bold text-[3rem]">
            Ready to get started?
          </p>
          <p
            onClick={showModal2}
            className="w-fit flex justify-center items-center h-12 font-[500] hover:scale-[102%] bg-white hover:bg-white/90 text-green-600 hover:text-green-800 hover:font-bold py-2 px-3 border border-green-100 shadow-xl cursor-pointer hover:shadow-amber-700/30 rounded"
          >
            Service Request
          </p>
        </div>
      </div>
      <div className="flex justify-center items-center my-8">
        <div className="">
          <p
            onClick={showModal}
            className="w-fit p-2 mx-auto border border-green-400 rounded-md shadow-lg text-green-700 hover:text-gray-300 hover:bg-green-800 cursor-pointer"
          >
            Signup to our NewsLetter
          </p>
        </div>
      </div>
      <div className="bg-[url('https://unsplash.com/photos/41X6FwTwPh4/download?ixid=M3wxMjA3fDB8MXxzZWFyY2h8MTB8fGluZHVzdHJ5fGVufDB8fHx8MTY5Mzg0NTc4N3ww&force=true&w=1920')]">
        <div className="bg-green-900/90 p-2 md:p-10">
          <div className="grid gap-4 md:grid-cols-4 w-full px-2 md:px-8 mx-auto text-gray-200">
            <div>
              <p className="text-[18px] text-green-200 border-b border-r pb-2 border-green-600">
                About Comacon
              </p>
              <div>
                <ul>
                  <Link to="/about">
                    <li className="cursor-pointer line-clamp-[8] my-2 text-[10px]">
                      With roots in East Africa dating back to January 2007,
                      Comacon Limited is a leading professional Civil,
                      Mechanical, and Electrical contractor. Comacon Limited is
                      now an affiliate of Building, Structural & Mass Concrete
                      Casting, which is ultimately a part of the leading
                      building technology and related services in the globe.
                      Projects of any scale or complexity can be taken up by
                      Comacon Limited anywhere in East Africa thanks to its
                      resources and capacity. The best value for money solutions
                      are ensured by a highly qualified and experienced team of
                      civil, mechanical, and electrical engineers working with a
                      team of operations professionals that are equally
                      qualified and motivated.
                    </li>
                  </Link>
                </ul>
              </div>
            </div>

            <div>
              <p className="text-[18px] border-b border-r pb-2 border-green-600">
                Also of Interest
              </p>
              <div>
                <ul className="list-disc px-10">
                  <Link to="/civil">
                    <li className="cursor-pointer my-2 text-[14px]">Civil </li>
                  </Link>

                  <Link to="/mechanical">
                    <li className="cursor-pointer my-2 text-[14px]">
                      Mechanical
                    </li>
                  </Link>
                  <Link to="/electrical">
                    <li className="cursor-pointer my-2 text-[14px]">
                      Electrical
                    </li>
                  </Link>
                  <Link to="/supplies">
                    <li className="cursor-pointer my-2 text-[14px]">
                      Supplies
                    </li>
                  </Link>
                </ul>
              </div>
            </div>

            <div className="">
              <p className="text-[18px] border-b border-r pb-2 border-green-600">
                Open Hours
              </p>
              <div>
                <ul className="text-[12px] py-3">
                  <li className="py-2">Mon - Fri 8.00am to 5.00pm</li>
                  <li className="py-2">Saturday 8.00am to 3.00pm</li>
                  <li>Sunday & holidays Closed</li>
                </ul>
              </div>
            </div>

            <div>
              <p className="text-[18px] border-b border-r pb-2 border-green-600">
                Get in Touch
              </p>
              <div>
                <ul>
                  <li className="cursor-pointer flex space-x-2 my-2 text-[14px]">
                    <CiLocationOn className="text-3xl md:text-2xl font-bold" />
                    <div>
                      <p className="text-[12px]">
                        <span className="text-gray-300">Headquaters:</span>
                        Lunga lunga Square, Lunga Lunga Road, Nairobi.
                      </p>
                    </div>
                  </li>
                  <li className="cursor-pointer flex space-x-2 my-2 text-[14px]">
                    <AiOutlinePhone className="text-2xl font-bold" />
                    <div>
                      <a href="tel:+254722675477">
                        <p className="text-[12px]">+254- 722 675 477</p>
                      </a>
                      <a href="tel:+254780675477">
                        <p className="text-[12px]">+254- 780 675 477</p>
                      </a>
                    </div>
                  </li>
                  <li>
                    <a href="https://wa.me/+254722675477">
                      <div className="flex space-x-4">
                        <FaWhatsapp className="text-2xl" />
                        <p className="text-[14px]">Whatsapp</p>
                      </div>
                    </a>
                  </li>
                  <li className="cursor-pointer flex space-x-1 my-2 text-[14px]">
                    <AiOutlineMail className="text-xl font-bold" />
                    <div>
                      <a href="mailto:info@comaconltd.com">
                        <p className="text-[14px]">info@comaconltd.com</p>
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex md:flex-row flex-col justify-between mt-6 md:mt-4 w-full px-2 mx-auto pb-2 text-[18px] font-[500]">
        <p className="text-[12px] text-gray-500">
          &copy; {new Date().getFullYear()} The Comacon Group | Terms and
          Conditions
        </p>
        <div className="flex justify-evenly text-gray-600 md:space-x-4 mt-6 md:mt-0">
          <a href="/" className="flex space-x-1 justify-center items-center">
            <AiFillLinkedin className="text-blue-500 text-[15px] " />{" "}
            <p className="text-[14px]">LinkedIn</p>
          </a>
          <a
            href="https://www.facebook.com/ComaconLimited"
            target="_blank"
            rel="noreferrer"
            className="flex space-x-1 justify-center items-center"
          >
            <BiLogoFacebook className="text-blue-700 text-[15px] " />
            <p className="text-[14px]">Facebook</p>
          </a>
          <a href="/" className="flex space-x-1 justify-center items-center">
            <BsTwitter className="text-blue-500 text-[15px] " />
            <p className="text-[14px]">Twitter</p>
          </a>
          <a href="/" className="flex space-x-1 justify-center items-center">
            <BsInstagram className="text-purple-700 text-[15px] " />
            <p className="text-[14px]">Instagram</p>
          </a>
        </div>
      </div>
      <div> </div>
      <Modal
        open={open}
        title=""
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Return
          </Button>,

          <Button loading={loading} onClick={handleOk}>
            Subscribe
          </Button>,
        ]}
      >
        <div>
          <p>Subscribe to Our News Letter</p>
          <Form onFinish={subscribe}>
            <Form.Item
              label="name"
              name="Name"
              rules={[
                {
                  required: true,
                  message: "Please input your name!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your email!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item name="weekly" valuePropName="checked">
              <Checkbox>Receive weekly Insights</Checkbox>
            </Form.Item>
          </Form>
        </div>
      </Modal>

      <Modal
        open={quote}
        title=""
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        footer={
          [
            // <Button key="back" onClick={handleCancel} className="border-none ">
            //   <p className="border-red-400 px-2 py-1 rounded border text-red-600 hover:border-red-600">
            //     Cancel
            //   </p>
            // </Button>,
          ]
        }
      >
        <div>
          <p>Request for a quote</p>
          <Form onFinish={reqQuote}>
            <div className="grid md:grid-cols-2 md:space-x-4 w-full">
              <Form.Item
                name="firstname"
                rules={[
                  {
                    required: true,
                    message: "Please input Your First Name!",
                  },
                ]}
              >
                <Input
                  prefix={<UserOutlined className="" />}
                  placeholder="First name"
                  className="h-[2.5rem]"
                />
              </Form.Item>
              <Form.Item
                name="lastname"
                rules={[
                  {
                    required: true,
                    message: "Please input Your Last Name!",
                  },
                ]}
              >
                <Input
                  prefix={<UserOutlined className="" />}
                  placeholder="Last name"
                  className="h-[2.5rem]"
                />
              </Form.Item>
            </div>
            <Form.Item
              name="email"
              rules={[
                {
                  pattern: /^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/,
                  message: "Provide a valid email",
                },
                {
                  required: true,
                  message: "Please input your email!",
                },
              ]}
            >
              <Input
                prefix={<MailOutlined />}
                className="h-[2.5rem]"
                placeholder="example@mail.com"
              />
            </Form.Item>
            <Form.Item
              name="message"
              rules={[
                {
                  min: 30,
                  message: "Minimimum character 30",
                },
                {
                  required: true,
                  message: "This field is required",
                },
              ]}
            >
              <Input.TextArea
                showCount
                maxLength={500}
                prefix={<UserOutlined />}
                placeholder="Type your message"
                className="h-[10rem]"
              />
            </Form.Item>
            <Form.Item className="flex justify-center">
              <Button
                htmlType="submit"
                disabled={loading}
                className="border-none text-white hover:text-gray-100 bg-green-500"
              >
                {loading ? (
                  <div className="flex space-x-3">
                    <p className="text-gray-200 hover:text-white">
                      Requesting...
                    </p>
                    <Spin />
                  </div>
                ) : (
                  <p className="text-gray-200 hover:text-white">Request</p>
                )}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );
}

export default Footer;
