import React from "react";
import { GiDrill } from "react-icons/gi";
import { Bounce } from "react-reveal";

function Mechanical() {
  const services = [
    {
      servicename: "Mechanical / Structural Design and erection",
    },
    {
      servicename:
        "Installation, Repair and Maintenance of Boilers and Steam Systems.",
    },
    {
      servicename:
        "Installation, Repair and maintenance of Industrial, Domestic LPG, Fire fighting and Fire protection systems.",
    },
    {
      servicename:
        "Installation Repair and maintenance of Petroleum Storage Handling Facility including",
    },
    {
      servicename: "Tank cleaning, Pumps, Compressors, Generators etc.",
    },
    {
      servicename:
        "Installation, Repair and maintenance of Industrial, Pneumatic, Hydraulic, Generators, air compressors.",
    },
    {
      servicename:
        "Pumps: Centrifugal and Submersible, Tokhem (Electronic dispensing and Mechanical consumer) pumps installation, repair and maintenance,",
    },
    {
      servicename: "Machines Installation, repair and maintenance",
    },
    {
      servicename: "Mechanical Fabrications and structural installation",
    },
    {
      servicename: "Process Piping, lugging and cladding",
    },
    {
      servicename: "Instrumentation and electronics",
    },
    {
      servicename: "Sand Blasting and spray painting",
    },
    {
      servicename: "Hydrostatic test",
    },
  ];

  return (
    <div>
      <div className="border-green-600 bg-fixed mt-10 bg-center text-center bg-white">
        <div className="py-4 bg-black/0">
          <Bounce top cascade>
            <p className="text-green-500 text-[2rem] animate__animated animate__fadeInRight md:text-[3rem] font-bold">
              Mechanical
            </p>
          </Bounce>
        </div>
      </div>

      <div className="grid px-4 md:px-10 md:grid-cols-2 gap-6">
        <div>
          <img
            src={require("../assets/images/mechanical.png")}
            alt=""
            className="rounded shadow-lg shadow-green-300/40"
          />
        </div>
        <div className="rounded shadow shadow-green-300/50 p-6">
          <p className="text-[23px] py-1 px-6 my-6 border-r border-b w-fit border-green-600 rounded text-green-600">
            Mechanical Services
          </p>
          <ul className="text-green-700">
            {services.map((service, index) => (
              <li key={index} className="">
                <div className="flex space-x-4 justify-start items-center">
                  <p className="w-3 h-3">
                    <GiDrill className="text-green-600 text-[15px]" />
                  </p>

                  <p className="p-1">{service.servicename}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Mechanical;
