import React from "react";
import { SiSololearn } from "react-icons/si";
import { Bounce } from "react-reveal";

function Civil() {
  const services = [
    {
      servicename: "Structural Design and Erection",
    },
    {
      servicename: "Materials and Concrete Technology",
    },
    {
      servicename: "Measurements",
    },
    {
      servicename: "Architectural and draftsmanship",
    },
    {
      servicename: "Estimating and Costing",
    },
    {
      servicename: "Soil Mechanics, Hydraulics and Geology",
    },
    {
      servicename: "Surveying",
    },
    {
      servicename: "Building Design and Building",
    },
    {
      servicename: "Construction",
    },
    {
      servicename: "Construction Management",
    },
  ];
  return (
    <div className="mt-10">
      <div className="border-green-600 bg-fixed mt-10 bg-center text-center bg-white">
        <div className="py-4 bg-black/0">
          <Bounce top cascade>
            <p className="text-green-500 text-[2rem] animate__animated animate__fadeInRight md:text-[3rem] font-bold">
              Civil
            </p>
          </Bounce>
        </div>
      </div>
      <div className="grid px-4 md:px-10 md:grid-cols-2 gap-6">
        <div>
          <img
            src={require("../assets/images/civil.png")}
            alt=""
            className="rounded shadow-lg shadow-green-300/40"
          />
        </div>
        <div className="rounded shadow shadow-green-300/50 p-6">
          <p className="text-[25px] py-1 px-6 my-6 border-r border-b w-fit border-green-600 rounded text-green-600">
            Our Civil Services
          </p>
          <ul className="text-green-700">
            {services.map((service, index) => (
              <li key={index}>
                <div className="flex space-x-4 justify-start items-center">
                  <SiSololearn className="text-green-600 text-[12px]" />
                  <p className="p-1">{service.servicename}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Civil;
