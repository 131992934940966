import { Button, Form, Input, Spin, message } from "antd";
import React, { useState } from "react";
import { UserOutlined, MailOutlined, PhoneOutlined } from "@ant-design/icons";
import axios from "axios";
import { sendMails } from "../utilities/Apis";
import { Bounce } from "react-reveal";

function Contact() {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const sendMessage = async (values) => {
    setLoading(true);
    try {
      const res = await axios.post(sendMails, {
        title: values.subject,
        name: `${values.firstname} ${values.lastname}`,
        email: values.email,
        phone: values.phone,
        body: "Message from a web visitor",
        message: values.message,
      });
      if (res.data.success) {
        setLoading(false);
        form.resetFields();
        message.info("Your message has been received we will reach back soon!");
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      message.error("Something went wrong");
    }
  };
  return (
    <div>
      <div className="border-green-600 bg-fixed mt-10 bg-center text-center bg-white">
        <div className="py-4 bg-black/0">
          <Bounce right cascade>
            <p className="text-green-500 text-[18px] animate__animated animate__fadeInRight md:text-[3rem] font-bold">
              Contact Us
            </p>
          </Bounce>
        </div>
      </div>

      {/* Conatct details */}
      <div className="grid md:grid-cols-2 gap-4">
        <div className="flex justify-center items-center p-10">
          <div className="text-center">
            <p className="text-2xl text-green-600">
              Have a Query? <span>Talk to Us</span>
            </p>
            <p className="text-gray-400 text-base">
              We will get back to you as early as we receive the message
            </p>
          </div>
        </div>
        <div className="p-2">
          <div className="rounded-lg shadow-green-500/50 border shadow-lg hover:border-green-500 p-10">
            <p className="text-gray-600 font-[500]">Phone:</p>
            <p className="text-gray-600 font-[300]">
              Availability Hours: <span>Monday - Saturday, 8.00 - 18.00</span>
            </p>
            <div className="flex-col text-gray-600">
              <p>
                <a href="tel:+254722675477">(+254) 722 675 477</a>
              </p>

              <p>
                <a href="tel:+254780675477">(+254) 780 675 477</a>
              </p>
            </div>
          </div>

          <div className="rounded-lg mt-4 shadow-green-500/50 border shadow-lg hover:border-green-500 p-10">
            <p className="text-gray-600 font-[500] mb-4">Email:</p>
            <p className="text-gray-600 font-[300]">
              Our support team will get back to you within 24hours on standard
              business hours.
            </p>
            <div className="flex-col text-gray-600">
              <p>
                <span className="mr-2">Send us an Email: </span>
                <a href="mailto:info@comaconltd.com">info@comaconltd.com</a>
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Location Zone */}
      <div className="mt-6">
        <p className="text-green-600 text-2xl font-[500] text-center">
          Our Location
        </p>
        <div className="min-h-[20rem] bg-green-200 flex justify-center items-center text-center">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15955.112150894452!2d36.860672!3d-1.308417!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f1178fb36272b%3A0x3a59d3cb4488ee55!2sComacon%20Limited!5e0!3m2!1sen!2ske!4v1698085006212!5m2!1sen!2ske"
            style={{ border: 0 }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
            className="w-full min-h-[25rem]"
          ></iframe>
        </div>
      </div>

      {/* Talk to us */}
      <div className="p-2 md:p-8">
        <p className="text-green-600 text-2xl font-[500] text-center">
          Talk to Us
        </p>
        <div className="flex justify-center items-center">
          <div className="w-full border border-green-500 shadow-lg shadow-green-500/40 rounded-lg md:w-[60%] self-center px-4 py-6 md:p-10">
            <Form
              form={form}
              name="normal_login"
              layout="vertical"
              className="login-form"
              initialValues={{
                remember: true,
              }}
              onFinish={sendMessage}
            >
              <div className="grid md:grid-cols-2 md:space-x-4 w-full">
                <Form.Item
                  label="First name"
                  name="firstname"
                  rules={[
                    {
                      required: true,
                      message: "Please input Your First Name!",
                    },
                  ]}
                >
                  <Input
                    prefix={<UserOutlined className="" />}
                    placeholder="First name"
                    className="h-[2.5rem]"
                  />
                </Form.Item>
                <Form.Item
                  label="Last name"
                  name="lastname"
                  rules={[
                    {
                      required: true,
                      message: "Please input Your Last Name!",
                    },
                  ]}
                >
                  <Input
                    prefix={<UserOutlined className="" />}
                    placeholder="Last name"
                    className="h-[2.5rem]"
                  />
                </Form.Item>
              </div>

              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please enter your Email!",
                  },
                ]}
              >
                <Input
                  prefix={<MailOutlined />}
                  placeholder="example@mail.com"
                  className="h-[2.5rem]"
                />
              </Form.Item>
              <Form.Item
                label="Phone"
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Please enter your Phone number!",
                  },
                ]}
              >
                <Input
                  prefix={<PhoneOutlined />}
                  placeholder="e.g +2547123123..."
                  className="h-[2.5rem]"
                />
              </Form.Item>
              <Form.Item
                label="Subject"
                name="subject"
                rules={[
                  {
                    required: true,
                    message: "Please provide a subject!",
                  },
                ]}
              >
                <Input
                  prefix={<MailOutlined />}
                  placeholder="subject"
                  className="h-[2.5rem]"
                />
              </Form.Item>
              <Form.Item
                label="Message"
                name="message"
                rules={[
                  {
                    required: true,
                    message: "Please enter your message",
                  },
                ]}
              >
                <Input.TextArea
                  showCount
                  maxLength={400}
                  prefix={<UserOutlined />}
                  placeholder="Type your message"
                  className="h-[10rem]"
                />
              </Form.Item>
              <Form.Item>
                <Button
                  className="bg-green-500 py-5 border-none flex justify-center items-center mt-6"
                  htmlType="submit"
                  disabled={loading}
                >
                  {loading ? (
                    <div className="flex space-x-3">
                      <p className="text-gray-200 hover:text-white">
                        Sending...
                      </p>
                      <Spin />
                    </div>
                  ) : (
                    <p className="text-gray-200 hover:text-white">Talk to Us</p>
                  )}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
