import { Link } from "react-router-dom";

export default function ErrorPage() {
  return (
    <div className="h-screen flex flex-col text-green-700 justify-center items-center">
      <h1>Oops!</h1>
      <p>Sorry, We couldn't find the page you were looking for.</p>
      <p>{/* <i>{error.statusText || error.message}</i> */}</p>
      <div className="p-4 text-green-900 italic font-bold cursor-pointer rounded-md mt-8">
        <Link to="/">--{">"}Take me Home</Link>
      </div>
    </div>
  );
}
