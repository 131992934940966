import React from "react";
import { BsTruckFlatbed } from "react-icons/bs";
import { Bounce } from "react-reveal";

function Supplies() {
  const services = [
    {
      servicename: "Skilled manpower",
    },
    {
      servicename: "Technical products",
    },
    {
      servicename: "Plant moving machines",
    },
    {
      servicename: "Fittings and Consumables",
    },
    {
      servicename: "Site Materials",
    },
  ];
  return (
    <div>
      <div className="border-green-600 bg-fixed mt-10 bg-center text-center bg-white">
        <div className="py-4 bg-black/0">
          <Bounce top cascade>
            <p className="text-green-500 text-[2rem] animate__animated animate__fadeInRight md:text-[3rem] font-bold">
              Supplies
            </p>
          </Bounce>
        </div>
      </div>

      <div className="grid px-4 md:px-10 md:grid-cols-2 gap-6">
        <div>
          <img
            src={require("../assets/images/mechanical.png")}
            alt=""
            className="rounded shadow-lg shadow-green-300/40"
          />
        </div>
        <div className="rounded shadow shadow-green-300/50 p-6">
          <p className="text-[23px] py-1 px-6 my-6 border-r border-b w-fit border-green-600 rounded text-green-600">
            Supplies
          </p>
          <ul className="text-green-700">
            <p className="my-2">We offer:</p>
            {services.map((service, index) => (
              <li key={index} className="ml-4">
                <div className="flex space-x-4 justify-start items-center">
                  <p className="w-3 h-3">
                    <BsTruckFlatbed className="text-green-600 text-[15px]" />
                  </p>

                  <p className="p-1">{service.servicename}</p>
                </div>
              </li>
            ))}
          </ul>
          <div className="text-green-700">
            <p className="my-2">
              We have a data bank of qualified skilled manpower and general
              manpower. Essentially, we are in touch with Manufactures of
              Technical products in Kenya and Overseas. We also supply all the
              Fittings, Pipes, and Valves as the need may arise.
            </p>
            <p>
              Comacon is also an International Company for Import & Export.
              Comacon with its long history and prominent presence in
              international market has a very successful business relationship
              with its suppliers and clients in many parts of East Africa and
              beyond.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Supplies;
