import { Carousel } from "antd";
import React from "react";
import { Bounce, Zoom } from "react-reveal";

function Projects() {
  return (
    <div>
      <div className="border-green-600 bg-fixed mt-10 bg-center text-center bg-white">
        <div className="py-4 bg-black/0">
          <Bounce top cascade>
            <p className="text-green-500 text-[2rem] md:text-[3rem] font-bold">
              Our Projects
            </p>
          </Bounce>
        </div>
      </div>
      <div className="text-center py-8">
        <Zoom right cascade>
          <p className="text-2xl text-green-800">
            Take a peek at Our Amazing Projects
          </p>
        </Zoom>
      </div>
      {/* Gallery */}
      <div className="md:p-3 p-2 grid sm:grid-cols-2 md:grid-cols-4 gap-3 md:px-[5%] bg-gray-100">
        <div className=" overflow-hidden rounded-lg">
          <img
            src={require("../assets/images/project-1.jpg")}
            alt=""
            className="h-auto rounded-lg"
          />
        </div>
        <div className=" overflow-hidden rounded-lg">
          <img
            src={require("../assets/images/project-2.jpg")}
            alt=""
            className="h-auto rounded-lg"
          />
        </div>
        <div className=" overflow-hidden rounded-lg">
          <img
            src={require("../assets/images/project-3.jpg")}
            alt=""
            className="h-auto rounded-lg"
          />
        </div>

        <div className=" overflow-hidden rounded-lg">
          <img
            src={require("../assets/images/project-6.jpg")}
            alt=""
            className="h-auto rounded-lg"
          />
        </div>
        <div className=" overflow-hidden rounded-lg ">
          <img
            src={require("../assets/images/project-7.jpg")}
            alt=""
            className="h-auto rounded-lg"
          />
        </div>
        <div className=" overflow-hidden rounded-lg">
          <img
            src={require("../assets/images/project-8.jpg")}
            alt=""
            className="h-auto rounded-lg"
          />
        </div>
        <div className="p-4">
          <img
            src={require("../assets/images/project-5.jpg")}
            alt=""
            className="rounded-lg"
          />
        </div>
        <div className="p-4">
          <img
            src={require("../assets/images/project-4.jpg")}
            alt=""
            className="h-auto rounded-lg"
          />
        </div>
        <div className="p-4">
          <img
            src={require("../assets/images/project10.jpg")}
            alt=""
            className="h-auto rounded-lg"
          />
        </div>
        <div className="p-4">
          <img
            src={require("../assets/images/project11.jpg")}
            alt=""
            className="h-auto rounded-lg"
          />
        </div>
        <div className="p-4">
          <img
            src={require("../assets/images/project12.jpg")}
            alt=""
            className="h-auto rounded-lg"
          />
        </div>
        <div className="p-4">
          <img
            src={require("../assets/images/project9.jpg")}
            alt=""
            className="h-auto rounded-lg"
          />
        </div>
      </div>
    </div>
  );
}

export default Projects;
