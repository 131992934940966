import React from "react";
import { Bounce } from "react-reveal";

function Lifting() {
  return (
    <div>
      <div className="my-3  bg-white">
        <div className="h-[6rem] flex items-center justify-center bg-black/10 px-[5rem]">
          <Bounce top cascade>
            <p className="text-green-500 text-[2rem] animate__animated animate__fadeInRight md:text-[3rem] font-bold">
              Heavy Lifting
            </p>
          </Bounce>
        </div>
      </div>

      <div className="grid md:grid-cols-2 px-2 md:px-10 md:gap-8">
        <div className="min-h-[35rem] my-2 md:my-10 rounded-md shadow-xl overflow-hidden shadow-green-200  bg-[url('https://unsplash.com/photos/0YVJKLtTAMc/download?ixid=M3wxMjA3fDB8MXxhbGx8fHx8fHx8fHwxNjk2MjMxNTI5fA&force=true&w=1920')] bg-center bg-cover">
          <div className="bg-black/30 h-full w-full"></div>
        </div>
        <div className="p-3 md:p-10 border rounded my-3 md:my-10">
          <p className="text-[25px] py-1 px-6 my-6 border-r border-b w-fit border-green-600 rounded text-green-600">
            Heavy Lifting
          </p>
          <p className="text-green-700 py-2 text-lg">
            Comacon has experience working in a variety of industries, including
            mining, oil and gas, petrochemical plants, and power plants, all of
            which require heavy lifting assistance during all phases of
            development, maintenance, and emergency repairs. Comacon has an
            up-to-date fleet of cranes, with capacities ranging from 25T to
            140T.
          </p>
          <p className="text-green-700 py-2 text-lg">
            Additionally, we have mastered the skills necessary to do
            challenging gantry lifts for heavy machinery and jack-and-slide
            setups.
          </p>
          <p className="text-green-700 py-2 text-lg">
            Each significant industrial sector can receive support from
            Comacon's heavy lifting section. We are able to provide our clients
            with services that will benefit them thanks to a mix of
            sophisticated equipment and an accomplished workforce. The primary
            goal of a lifting study and method is to ensure that the work is
            completed safely while reducing the critical path so that facilities
            can resume normal operations on schedule.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Lifting;
