import React, { useState } from "react";
import { ConfigProvider, FloatButton } from "antd";
import Profile from "../assets/files/COMACONPROFILE2023.pdf";
import { PlusOutlined, WhatsAppOutlined } from "@ant-design/icons";

import { Link, Outlet, useLocation } from "react-router-dom";
import Footer from "./Footer";

function Appbar() {
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };
  return (
    <ConfigProvider
      theme={{
        token: {
          // Seed Token
          colorPrimary: "#00b96b",
          borderRadius: 2,

          // Alias Token
          colorBgContainer: "bg-green-400",
        },
      }}
    >
      <div className="pt-14 md:pt-16 md:mt-6">
        <nav className="md:px-5 md:py-1 bg-white/90 md:bg-white/95 shadow-xl pt-2 mb-2 w-full fixed top-0 z-10">
          {/* Desktop menu */}
          <div className="space-x-8 pl-10  w-full z-10 hidden py-2 lg:block">
            <div className="flex space-x-[20%] items-center">
              <div className="text-gray-500">
                <a href="/">
                  <img
                    src={require("../assets/images/logocomacon.png")}
                    alt=""
                    className="h-16 w-auto rounded-full"
                  />
                </a>
              </div>
              <ul className="flex items-center space-x-4 tracking-tight pr-[5rem]  text-green-900 font-[600]">
                <Link
                  to={"/"}
                  className={`flex space-x-3 justify-center items-center cursor-pointer text-green-700 font-[500] transition transform motion-reduce:transition-none motion-reduce:hover:transform-none hover:font-[700] hover:scale-110 ${
                    location.pathname === "/" &&
                    "text-red-700 scale-110 font-bold"
                  }`}
                >
                  <li>Home</li>
                </Link>
                <Link
                  to={"/about"}
                  className={`flex space-x-3 justify-center items-center cursor-pointer text-green-700 font-[500] transition transform motion-reduce:transition-none motion-reduce:hover:transform-none hover:font-[700] hover:scale-110 ${
                    location.pathname === "/about" && "text-red-700 font-[700]"
                  }`}
                >
                  <li>About</li>
                </Link>
                <Link
                  to="/services"
                  className={`flex space-x-3 justify-center items-center cursor-pointer text-green-700 font-[500] transition transform motion-reduce:transition-none motion-reduce:hover:transform-none hover:font-[700] hover:scale-110 ${
                    location.pathname === "/services" &&
                    "text-red-700 font-[700]"
                  }`}
                >
                  <p>Services</p>
                </Link>

                <Link
                  to={"/projects"}
                  className={`flex space-x-3 justify-center items-center cursor-pointer text-green-700 font-[500] transition transform motion-reduce:transition-none motion-reduce:hover:transform-none hover:font-[700] hover:scale-110 ${
                    location.pathname === "/projects" &&
                    "text-red-700 font-[700]"
                  }`}
                >
                  <li>Projects</li>
                </Link>

                <Link
                  to={"/contact-us"}
                  className={`flex space-x-3 justify-center items-center cursor-pointer text-green-700 font-[500] transition transform motion-reduce:transition-none motion-reduce:hover:transform-none hover:font-[700] hover:scale-110 ${
                    location.pathname === "/contact-us" &&
                    "text-red-700 font-[700]"
                  }`}
                >
                  <li>Contact</li>
                </Link>
                <Link
                  to={"/environ-safety"}
                  className={`flex space-x-3 justify-center items-center cursor-pointer text-green-700 font-[500] transition transform motion-reduce:transition-none motion-reduce:hover:transform-none hover:font-[700] hover:scale-110 ${
                    location.pathname === "/environ-safety" &&
                    "text-red-700 font-[700]"
                  }`}
                >
                  <li>Environmental Safety</li>
                </Link>
                <a
                  className="flex space-x-3 justify-center items-center cursor-pointer text-green-700 font-[500] transition transform motion-reduce:transition-none motion-reduce:hover:transform-none hover:font-[700] hover:scale-110"
                  href={Profile}
                  target="_blank"
                  rel="noreferrer"
                >
                  Resources
                </a>
              </ul>
            </div>
          </div>

          {/* Mobile menu */}
          <div className="block lg:hidden py-2">
            <div className="flex w-full justify-between px-4 ">
              <div>
                <a href="/">
                  <img
                    src={require("../assets/images/logocomacon.png")}
                    alt=""
                    className="h-10 w-auto rounded-full"
                  />
                </a>
              </div>
              <div onClick={() => setOpen(!open)}>
                {open ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-8 h-8"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-8 h-8"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                    />
                  </svg>
                )}
              </div>
            </div>
            {open && (
              <div>
                <ul className="flex animate__animated animate__fadeIn flex-col py-4 space-y-4 mt-2 w-full  text-gray-900 font-[300]">
                  <Link
                    onClick={() => setOpen(!open)}
                    to={"/"}
                    className={`flex space-x-3 justify-center items-center cursor-pointer text-green-700 font-[300] transition transform motion-reduce:transition-none motion-reduce:hover:transform-none hover:font-[700] hover:scale-110 ${
                      location.pathname === "/" && "text-red-700 font-[700]"
                    }`}
                  >
                    <li>Home</li>
                  </Link>
                  <Link
                    onClick={() => setOpen(!open)}
                    to={"/about"}
                    className={`flex space-x-3 justify-center items-center cursor-pointer text-green-700 font-[300] transition transform motion-reduce:transition-none motion-reduce:hover:transform-none hover:font-[700] hover:scale-110 ${
                      location.pathname === "/about" &&
                      "text-red-700 font-[700]"
                    }`}
                  >
                    <li>About us</li>
                  </Link>
                  <Link
                    onClick={() => setOpen(!open)}
                    to="/services"
                    className={`flex space-x-3 justify-center items-center cursor-pointer text-green-700 font-[300] transition transform motion-reduce:transition-none motion-reduce:hover:transform-none hover:font-[700] hover:scale-110 ${
                      location.pathname === "/services" &&
                      "text-red-700 font-[700]"
                    }`}
                  >
                    <li>Services</li>
                  </Link>

                  <Link
                    onClick={() => setOpen(!open)}
                    to={"/projects"}
                    className={`flex space-x-3 justify-center items-center cursor-pointer text-green-700 font-[300] transition transform motion-reduce:transition-none motion-reduce:hover:transform-none hover:font-[700] hover:scale-110 ${
                      location.pathname === "/projects" &&
                      "text-red-700 font-[700]"
                    }`}
                  >
                    <li>Our Projects</li>
                  </Link>

                  <Link
                    onClick={() => setOpen(!open)}
                    to={"/contact-us"}
                    className={`flex space-x-3 justify-center items-center cursor-pointer text-green-700 font-[300] transition transform motion-reduce:transition-none motion-reduce:hover:transform-none hover:font-[700] hover:scale-110 ${
                      location.pathname === "/contact-us" &&
                      "text-red-700 font-[700]"
                    }`}
                  >
                    <li>Contact Us</li>
                  </Link>
                  <Link
                    onClick={() => setOpen(!open)}
                    to={"/environ-safety"}
                    className={`flex space-x-3 justify-center items-center cursor-pointer text-green-700 font-[300] transition transform motion-reduce:transition-none motion-reduce:hover:transform-none hover:font-[700] hover:scale-110 ${
                      location.pathname === "/environ-safety" &&
                      "text-red-700 font-[700]"
                    }`}
                  >
                    <li>Environmental Safety</li>
                  </Link>
                  <a
                    className="flex space-x-3 justify-center items-center cursor-pointer text-green-700 font-[300] transition transform motion-reduce:transition-none motion-reduce:hover:transform-none hover:font-[700] hover:scale-110"
                    href={Profile}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Resources
                  </a>
                </ul>
              </div>
            )}
          </div>
        </nav>
        <Outlet />
        <FloatButton.Group
          trigger="hover"
          type="primary"
          style={{ right: 24 }}
          icon={<PlusOutlined />}
        >
          <FloatButton
            tooltip="Talk to us"
            icon={
              <WhatsAppOutlined
                onClick={() => openInNewTab("https://wa.me/+254722675477")}
                className="text-green-400 text-[23px]"
              />
            }
          />
          <FloatButton.BackTop tooltip="Back to top" />
        </FloatButton.Group>

        <Footer />
      </div>
    </ConfigProvider>
  );
}

export default Appbar;
