import React from "react";
import { Link } from "react-router-dom";
import { Bounce, Zoom } from "react-reveal";

function Welcome() {
  const handleClickScroll = () => {
    const element = document.getElementById("prefabrication");
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div className="w-full">
      <div className="pt-6">
        <Zoom top cascade>
          <p className="text-center text-green-700 font-bold text-[1.5rem] ">
            Comacon Limited
          </p>
        </Zoom>
        <div>
          <p className="text-green-700 text-center text-[13px] md:text-[20px] md:px-10 px-4">
            Comacon Limited is a leading specialist Civil, Mechanical &
            Electrical contractor within East Africa with a history dating back
            to January 2007. Today, Comacon Limited forms part of Building,
            Structural & Mass Concrete casting which is ultimately part of the
            world's leading building technology and associated services. Comacon
            Limited has the capability and resources to undertake projects of
            all sizes and complexity throughout East Africa. A well qualified
            and experienced team of Civil, Mechanical and Electrical engineers,
            coupled with an equally experienced and energetic operations team,
            guarantees value for money solutions of the highest standard
          </p>
        </div>

        <div className="border-green-600 bg-fixed mt-10 bg-center text-center bg-white">
          <div className="py-4 bg-black/10">
            <Bounce top cascade>
              <p className="text-[1.5rem] text-green-600 md:text-[2.5rem] uppercase font-bold">
                Operational Divisions
              </p>
            </Bounce>
          </div>
        </div>
        <Zoom>
          <div className="grid md:grid-cols-4 gap-1 p-2 text-gray-700 text-[30px]">
            <Link
              to="/mechanical"
              className="hover:bg-red-300 bg-green-400 h-[7rem] flex justify-center cursor-pointer border border-green-700 bg-transparent items-center hover:scale-[98%] ease-in-out delay-100 duration-300"
            >
              <p>Mechanical</p>
            </Link>
            <Link
              to="/electrical"
              className="hover:bg-red-300 bg-green-400 h-[7rem] flex justify-center cursor-pointer border border-green-700 bg-transparent items-center hover:scale-[98%] ease-in-out delay-100 duration-300"
            >
              Electrical
            </Link>
            <Link
              to="/civil"
              className="hover:bg-red-300 bg-green-400 h-[7rem] flex justify-center cursor-pointer border border-green-700 bg-transparent items-center hover:scale-[98%] ease-in-out delay-100 duration-300"
            >
              <p>Civil/Structural</p>
            </Link>
            <Link
              to="/supplies"
              className="hover:bg-red-300 bg-green-400 h-[7rem] flex justify-center cursor-pointer border border-green-700 bg-transparent items-center hover:scale-[98%] ease-in-out delay-100 duration-300"
            >
              <p>Transport & Supplies</p>
            </Link>
          </div>
          <div className="grid md:grid-cols-3 gap-1 px-2 text-gray-700 text-[30px]">
            <Link
              to="/Oil&gas"
              className="hover:bg-red-300 bg-green-100 h-[7rem] flex justify-center cursor-pointer border border-green-700 bg-transparent items-center hover:scale-[98%] ease-in-out delay-100 duration-300"
            >
              <p>Oil & Gas</p>
            </Link>
            <Link
              to="/heavy-lifting"
              className="hover:bg-red-300 bg-green-100 h-[7rem] flex justify-center cursor-pointer border border-green-700 bg-transparent items-center hover:scale-[98%] ease-in-out delay-100 duration-300"
            >
              <p>Heavy Lifting</p>
            </Link>
            <div
              onClick={handleClickScroll}
              className="hover:bg-red-300 bg-green-100 h-[7rem] flex justify-center cursor-pointer border border-green-700 bg-transparent items-center text-[16px] hover:text-[18px] hover:scale-[98%] ease-in-out delay-100 duration-300"
            >
              <p className="text-center">
                {" "}
                Prefabrication, Assembly & Modularization
              </p>
            </div>
          </div>
        </Zoom>
      </div>
    </div>
  );
}

export default Welcome;
