import React from "react";
import { Slide } from "react-reveal";
import { Link } from "react-router-dom";

function Services() {
  return (
    <div className="bg-[url('https://unsplash.com/photos/x-ghf9LjrVg/download?ixid=M3wxMjA3fDB8MXxzZWFyY2h8M3x8YnVpbGRpbmclMjBjb25zdHJ1Y3Rpb258ZW58MHx8fHwxNjk1OTcwMzQ3fDA&force=true&w=1920')] bg-center bg-cover">
      <div className="bg-white/90 py-10">
        <p className="text-center text-[2rem] font-bold text-green-700">
          <Slide cascade top>
            Comacon Limited
          </Slide>
        </p>
        <p className="text-center text-[15px] text-gray-500">
          We offer the following services
        </p>
        <div className="grid sm:grid-cols-2 md:px-[10%] px-10 mt-4 gap-4 md:gap-8">
          <Link
            to="/civil"
            className="border hover:motion-safe:animate-bounce border-green-300 hover:scale-[102%] p-6 bg-gray-200 shadow hover:shadow-lg shadow-black/50 rounded text-center  ease-in-out delay-100 duration-300"
          >
            <p className="text-green-700 py-1 mb-2 text-[20px] w-fit px-6 border-b border-green-800">
              Civil/Structural
            </p>
            <p className="text-green-800">
              At Comacon Limited, we excel in civil engineering services,
              harnessing the expertise of our highly qualified team. We execute
              projects using the latest technologies and state-of-the-art
              equipment available in the industry
            </p>
          </Link>
          <Link
            to="/electrical"
            className="border hover:animate-spin border-green-300 hover:scale-[102%] p-6 bg-gray-200 shadow hover:shadow-lg shadow-black/50 rounded text-center  ease-in-out delay-100 duration-300"
          >
            <p className="text-green-700 py-1 mb-2 text-[20px] w-fit px-6 border-b border-green-800">
              Electrical
            </p>
            <p className="text-green-800">
              Electrical services are provided by Comacon Limited. We carry out
              operations using the most cutting-edge technology and equipment
              available thanks to our highly skilled personnel.
            </p>
          </Link>
          <Link
            to="/mechanical"
            className="border hover:animate-bounce border-green-300 hover:scale-[102%] p-6 bg-gray-200 shadow hover:shadow-lg shadow-black/50 rounded text-center  ease-in-out delay-100 duration-300"
          >
            <p className="text-green-700 py-1 mb-2 text-[20px] w-fit px-6 border-b border-green-800">
              Mechanical
            </p>
            <p className="text-green-800">
              At Comacon Limited, we offer our customers mechanical services. To
              conduct operations, we make use of the latest technologies and
              equipment. Top-notch services are provided by our team of
              professionals.
            </p>
          </Link>
          <Link
            to="/supplies"
            className="border border-green-300 hover:scale-[102%] p-6 bg-gray-200 shadow hover:shadow-lg shadow-black/50 rounded text-center  ease-in-out delay-100 duration-300"
          >
            <p className="text-green-700 py-1 mb-2 text-[20px] w-fit px-6 border-b border-green-800">
              Transport and supplies
            </p>
            <p className="text-green-800">
              At Comacon Limited, we are your trusted partner for transport and
              supply solutions. Our experienced team ensures seamless
              operations, harnessing advanced tools and resources for efficiency
            </p>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Services;
